import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/docs/src/components/Guide.js"
import Button from 'components/Button.js';

export default ({components, ...props}) => <MDXTag name="wrapper" Layout={DefaultLayout} layoutProps={props} components={components}>


<MDXTag name="p" components={components}>{`The letterhead template can be printed as is or added to a Word document as a background image.`}</MDXTag>
<Button
    download
    href="/HealthShare-Letterhead.zip"
    label="Download letterhead"
/></MDXTag>

export const _frontmatter = {};

  